define([
    'lodash',
    'thirdPartyAnalytics'
], function (_, thirdPartyAnalytics) {
    'use strict';

    function getDefaultChannels(siteAPI, promoteAnalyticsAdapter) {
        const siteData = siteAPI.getSiteData();
        
        return [{
            // Sends events to frog.wix.com
            name: promoteAnalyticsAdapter.channelNames.BI_ANALYTICS,
            report: thirdPartyAnalytics.decorateReporter(
                siteData,
                promoteAnalyticsAdapter.channelNames.BI_ANALYTICS,
                params => siteAPI.reportBiAnalytics(params)
            )
        }];
    }

    function getEmbeddedChannels(siteAPI) {
        if (typeof window === 'undefined' || !window.promoteAnalyticsChannels) {
            return [];
        }

        const siteData = siteAPI.getSiteData();

        return window.promoteAnalyticsChannels.map(channel => ({
            name: channel.name,
            report: thirdPartyAnalytics.decorateReporter(siteData, channel.name, channel.report),
            config: channel.config,
            events: channel.events
        }));
    }
    
    function getManagedChannels(siteAPI, promoteAnalyticsAdapter) {
        const accountNameToChannelData = {
            facebookRemarketing: {
                name: promoteAnalyticsAdapter.channelNames.FACEBOOK_PIXEL,
                report: siteAPI.fireFacebookCustomEvent
            },
            googleAnalytics: {
                name: promoteAnalyticsAdapter.channelNames.GOOGLE_ANALYTICS,
                report: siteAPI.reportGoogleAnalytics
            },
            googleTagManager: {
                name: promoteAnalyticsAdapter.channelNames.GOOGLE_TAG_MANAGER,
                report: siteAPI.reportGoogleTagManager
            }
        };

        const siteData = siteAPI.getSiteData();

        return _.filter(accountNameToChannelData,
            (channelData, accountName) => thirdPartyAnalytics.hasAccount(siteData, accountName));
    }

    return {
        trackEvent(siteAPI, eventName, params, options) {
            const siteData = siteAPI.getSiteData();
            if (!siteData.serviceTopology.scriptsLocationMap['promote-analytics-adapter']) {
                return;
            }
            
            requirejs(['promote-analytics-adapter'], function (promoteAnalyticsAdapter) {
                if (!promoteAnalyticsAdapter.api) {
                    return;
                }

                const channels = [
                    ...getDefaultChannels(siteAPI, promoteAnalyticsAdapter),
                    ...getManagedChannels(siteAPI, promoteAnalyticsAdapter),
                    ...getEmbeddedChannels(siteAPI)
                ];

                promoteAnalyticsAdapter.api.init(channels);
                promoteAnalyticsAdapter.api.trackEvent(eventName, params, options);
            });
        },

        reportPageView(siteAPI, params) {
            this.trackEvent(siteAPI, 'PageView', params);
        }
    };
});
