define([
    'lodash'
], function (_) {
    'use strict';

    let raven = null;
    let siteModel = null;

    function getNumberOfPages() {
        return _.get(siteModel, 'rendererModel.pageList.pages.length');
    }

    function isSiteFromADI(clientSpecMap) {
        const onBoardingSpec = _.find(clientSpecMap, {type: 'onboarding'});
        return _.get(onBoardingSpec, 'inUse', false) === true;
    }

    function getTAGS() {
        return {
            publicUrl: _.get(siteModel, 'publicModel.externalBaseUrl'),
            numberOfPages: getNumberOfPages(),
            revision: _.get(siteModel, 'publicModel.siteRevision'),
            geo: _.get(siteModel, 'rendererModel.geo'),
            metaSiteId: _.get(siteModel, 'rendererModel.metaSiteId'),
            isFromAdi: isSiteFromADI(_.get(siteModel, 'rendererModel.clientSpecMap'))
        };
    }

    function setCurrentRelease() {
        const currentRelease = _.get(siteModel, 'baseVersion');
        const splitVersion = _.split(currentRelease, '.');
        const patchVersion = _.takeRight(splitVersion);
        if (currentRelease && currentRelease !== 'unknown' && patchVersion[0] > 0) {
            raven.setTagsContext({patchVersion: patchVersion[0]});
            raven.setRelease(_(splitVersion)
                .dropRight()
                .concat('x')
                .join('.'));
        } else {
            raven.setShouldSendCallback(() => false);
            raven.uninstall();
        }
    }

    function getDefaultContext() {
        let defaultContext = {
            premiumFeatures: _.get(siteModel, 'rendererModel.premiumFeatures')
        };
        if (siteModel.documentServicesModel) {
            defaultContext = _.merge(defaultContext, {
                editorSessionId: _.get(siteModel, 'documentServicesModel.editorSessionId'),
                userEmail: _.get(siteModel, 'documentServicesModel.userInfo.email'),
                isPublished: _.get(siteModel, 'documentServicesModel.isPublished')
            });
        }
        return defaultContext;
    }

    function setUser() {
        raven.setUserContext({
            id: _.get(siteModel, 'siteHeader.userId')
        });
    }

    function isWixCodeError(data) {
        const exceptionFrames = _.get(data, ['exception', 'values', 0, 'stacktrace', 'frames'], []);
        return exceptionFrames
            .filter(frame => frame && _.isString(frame.filename))
            .some(frame => frame.filename.includes('wixCode') || frame.filename.includes('wix-code'));
    }

    function checkForFullStory(data) {
        if (window && window.FS && _.isFunction(window.FS.getCurrentSessionURL)) {
            const exceptionMessage = _.get(data, ['exception', 'values', 0, 'value'], '');
            data.tags = _.assign(data.tags || {}, {withFullStory: true});
            data.extra = _.assign(data.extra, {
                fullstory: {
                    url: window.FS.getCurrentSessionURL(),
                    session: window.FS.getCurrentSession()
                }
            });
            data.fingerprint = _.concat(data.fingerprint || [], ['hasFullStory']);
            _.set(data, ['exception', 'values', 0, 'value'], `${exceptionMessage} (with fullstory)`);
        }
    }

    function setErrorCallbacks() {
        raven.setDataCallback((data, originalCallback = _.identity) => {
            const exceptionFrames = _.get(data, ['exception', 'values', 0, 'stacktrace', 'frames'], []);
            if (isWixCodeError(data)) {
                data.tags = _.assign(data.tags || {}, {'wix-code-error': true});
            }
            checkForFullStory(data);

            if (_.has(data.tags, 'errorType')) {
                data.fingerprint = ['errorType'];
            } else if (exceptionFrames.length) {
                _.set(data, ['exception', 'values', 0, 'stacktrace', 'frames'], _.map(exceptionFrames, frame => {
                    if (frame && _.isString(frame.filename)) {
                        frame.abs_path = frame.filename;
                        frame.filename = _.last(_.split(frame.filename, '/'));
                    }
                    return frame;
                }));
                const lastFrame = _.last(exceptionFrames);
                data.fingerprint = _.concat(data.fingerprint || [], [lastFrame.filename, lastFrame.lineno, lastFrame.colno]);
            }

            return originalCallback(data);
        });
    }

    const noopTpaInstance = {
        setUser: _.noop,
        setReleaseContext: _.noop,
        noConflict: _.noop,
        config: _.noop,
        install: _.noop,
        setDSN: _.noop,
        context: _.noop,
        wrap: _.noop,
        uninstall: _.noop,
        captureException: _.noop,
        captureMessage: _.noop,
        captureBreadcrumb: _.noop,
        addPlugin: _.noop,
        setUserContext: _.noop,
        setExtraContext: _.noop,
        setTagsContext: _.noop,
        clearContext: _.noop,
        getContext: _.noop,
        setEnvironment: _.noop,
        setRelease: _.noop,
        setDataCallback: _.noop,
        setBreadcrumbCallback: _.noop,
        setShouldSendCallback: _.noop,
        setTransport: _.noop,
        lastException: _.noop,
        lastEventId: _.noop,
        isSetup: _.noop,
        afterLoad: _.noop,
        showReportDialog: _.noop
    };

    function isQA() {
        return !!_.get(siteModel, 'currentUrl.query["isqa"]');
    }

    function isDebug() {
        return !!_.get(siteModel, 'currentUrl.query["debug"]');
    }

    function setSentrySdkCurrentRelease(sentrySDK) {
        const shouldUninstall = isQA() || isDebug();
        const currentRelease = _.get(siteModel, 'baseVersion');
        const splitVersion = _.split(currentRelease, '.');
        const patchVersion = _.takeRight(splitVersion);
        if (!shouldUninstall && currentRelease && currentRelease !== 'unknown' && patchVersion[0] > 0) {
            sentrySDK.configureScope(scope => {
                scope.addEventProcessor(
                    event => {
                        const release = _(splitVersion)
                            .dropRight()
                            .concat('x')
                            .join('.');
                        return _.assign(event, {
                            environment: 'production',
                            release
                        });
                    });
                scope.setTag('patchVersion', patchVersion[0]);
            });
        } else {
            sentrySDK.configureScope(scope =>
                scope.addEventProcessor(
                    event => _.assign(event, {environment: 'development'})
                )
            );
        }
    }

    function setSentrySdkUser(sentrySDK) {
        sentrySDK.configureScope(scope => {
            scope.setUser({
                id: _.get(siteModel, 'siteHeader.userId')
            });
        });
    }

    function configureSentrySdkScope(sentrySDK) {
        sentrySDK.configureScope(scope => {
            const existingTags = _.cloneDeep(scope.tags);
            const tagsToSet = _.defaults(existingTags, getTAGS());
            _.forOwn(tagsToSet, (value, key) => {
                scope.setTag(key, value);
            });
        });
    }

    function setSentrySdkErrorCallbacks(sentrySDK) {
        sentrySDK.configureScope(scope => {
            scope.addEventProcessor(data => {
                const exceptionFrames = _.get(data, ['exception', 'values', 0, 'stacktrace', 'frames'], []);
                if (isWixCodeError(data)) {
                    data.tags = _.assign(data.tags || {}, {'wix-code-error': true});
                }
                checkForFullStory(data);

                if (exceptionFrames.length) {
                    _.set(data, ['exception', 'values', 0, 'stacktrace', 'frames'], _.map(exceptionFrames, frame => {
                        if (frame && _.isString(frame.filename)) {
                            frame.abs_path = frame.filename;
                            frame.filename = _.last(_.split(frame.filename, '/'));
                        }
                        return frame;
                    }));
                    const lastFrame = _.last(exceptionFrames);
                    data.fingerprint = _.concat(data.fingerprint || [], [lastFrame.filename, lastFrame.lineno, lastFrame.colno]);
                }

                return data;
            });
        });
    }

    return {
        initRavenUtils(model) {
            siteModel = model;
            raven = _.get(siteModel, 'Raven', null);
            if (raven) {
                this.start = () => {
                    setCurrentRelease();
                    setUser();
                    raven.setTagsContext(getTAGS());
                    raven.setExtraContext(getDefaultContext());
                    setErrorCallbacks();
                };

                this.setGlobalTagForErrors = tags => {
                    raven.setTagsContext();
                    raven.setTagsContext(_.defaults(tags, getTAGS()));
                };

                this.setGlobalContextForErrors = context => {
                    raven.setExtraContext();
                    raven.setExtraContext(_.defaults(context, getDefaultContext()));
                };

                this.sendInfoMessage = text => {
                    raven.captureMessage(text, {
                        level: 'info'
                    });
                };

                this.setTag = tags => {
                    raven.setTag(tags);
                };

                this.uninstall = () => {
                    raven.uninstall();
                };

                this.captureError = (error, additionalData) => {
                    raven.captureException(error, additionalData);
                };

                this.breadcrumb = (message, additionalData) => {
                    raven.captureBreadcrumb({
                        message,
                        additionalData
                    });
                };
            }
        },
        initSentryUtils(model) {
            siteModel = model;
            const sentrySDK = siteModel.SentrySDK;
            if (sentrySDK) {
                setSentrySdkCurrentRelease(sentrySDK);
                setSentrySdkUser(sentrySDK);
                configureSentrySdkScope(sentrySDK);
                setSentrySdkErrorCallbacks(sentrySDK);

                this.captureError = (error, additionalData) => {
                    sentrySDK.withScope(scope => {
                        _.forOwn(additionalData.tags, (value, key) => {
                            scope.setTag(key, value);
                        });
                        _.forOwn(additionalData.extra, (value, key) => {
                            scope.setExtra(key, value);
                        });
                        scope.setLevel(additionalData.level || 'error');
                        if (additionalData.tags && additionalData.groupErrorsBy === 'tags') {
                            scope.setFingerprint(_.keys(additionalData.tags));
                        } else if (additionalData.groupErrorsBy === 'values') {
                            scope.setFingerprint(_.values(additionalData.tags));
                        }
                    });
                    sentrySDK.captureException(error);
                };

                this.sendInfoMessage = (text, level = 'info') => {
                    sentrySDK.withScope(scope => {
                        scope.setLevel(level);
                        sentrySDK.captureMessage(text);
                    });
                };

                this.setGlobalTagForErrors = newGlobalTags => {
                    sentrySDK.configureScope(scope => {
                        _.forOwn(newGlobalTags, (value, key) => {
                            scope.setTag(key, value);
                        });
                    });
                };

                this.setGlobalContextForErrors = context => {
                    sentrySDK.configureScope(scope => {
                        _.forOwn(context, (value, key) => {
                            scope.setExtra(key, value);
                        });
                    });
                };

                this.breadcrumb = (message, additionalData = {}) => {
                    sentrySDK.addBreadcrumb({
                        data: additionalData,
                        message
                    });
                };
            }
        },
        createNewInstance() {
            if (raven) {
                return new raven.Client();
            }
            return noopTpaInstance;
        },
        createNewSentryInstance(dsn) {
            return _.get(siteModel, 'SentrySDK') ? new siteModel.SentrySDK.BrowserClient({dsn}) : noopTpaInstance;
        },
        start: _.noop,
        setGlobalTagForErrors: _.noop,
        setGlobalContextForErrors: _.noop,
        sendInfoMessage: _.noop,
        setTag: _.noop,
        uninstall: _.noop,
        captureError: _.noop,
        breadcrumb: _.noop
    };
});
