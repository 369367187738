define(['lodash'], function (_) {
    'use strict';

    const CONTROLLER_OVERRIDE_QUERY_PARAM_NAME = 'controllersUrlOverride';
    const WIDGET_COMPONENT_OVERRIDE_QUERY_PARAM_NAME = 'widgetsUrlOverride';


    const getControllersOverride = queryParams => _.get(queryParams, CONTROLLER_OVERRIDE_QUERY_PARAM_NAME);
    const getWidgetsOverride = queryParams => _.get(queryParams, WIDGET_COMPONENT_OVERRIDE_QUERY_PARAM_NAME);


    const isValidOverrideUrl = (url, scriptsLocation) =>
        _.startsWith(url, scriptsLocation) ||
        _.startsWith(url, 'http://localhost') ||
        _.startsWith(url, 'https://localhost');


    const getUrlOverrideForWidget = (widgetId, widgetsOverrideParamValue, scriptsLocation) =>
        getUrlOverrideForScript(widgetId, widgetsOverrideParamValue, scriptsLocation);

    const getUrlOverrideForControllerScript = (controllerId, scriptsOverrideParamValue, scriptsLocation) =>
        getUrlOverrideForScript(controllerId, scriptsOverrideParamValue, scriptsLocation);


    const getUrlOverrideForScript = (id, paramValue, scriptsLocation) => {
        if (paramValue) {
            const allOverridesMap = paramValue.split(',');

            const scriptOverrideMap = _(allOverridesMap)
                .invokeMap('split', '=')
                .fromPairs()
                .value();

            const scriptOverrideUrl = scriptOverrideMap[id];


            if (scriptOverrideUrl && scriptsLocation) {
                if (!isValidOverrideUrl(scriptOverrideUrl, scriptsLocation)) {
                    console.error(`URL override ${scriptOverrideUrl} is invalid. please use a URL served from ${scriptsLocation} or localhost`); //eslint-disable-line no-console
                    return;
                }
            }

            return scriptOverrideUrl;
        }
    };

    return {
        getControllersOverride,
        getWidgetsOverride,
        getUrlOverrideForWidget,
        getUrlOverrideForControllerScript,
        isValidOverrideUrl
    };
});
