define([
    'lodash',
    'coreUtilsLib',
    'warmupUtils'
], function (
    _,
    coreUtilsLib,
    warmupUtils
) {
    'use strict';

    const {fragment, svgUtils} = coreUtilsLib;
    const {SVG_ROOT, EMPTY_SHAPE, SVG_TYPES, SVG_STORES, svgIdToUrl} = svgUtils;

    /**
     * For dataRequirementsChecker
     * @param {string} mediaRootUrl
     * @param {SiteData} siteData
     * @param {string} svgId
     * @returns {{}|null}}
     */
    function createSvgFetchRequest(mediaRootUrl, siteData, svgId) {
        if (warmupUtils.siteDataUtils.getSvgString(siteData, svgId)) {
            return null;
        }

        return {
            destination: [SVG_ROOT, svgId],
            name: 'svg shape',
            url: svgIdToUrl(mediaRootUrl, svgId),
            dataType: 'html',
            transformFunc: svgStringToStoreData,
            error() {
                // set data to a default, empty shape
                warmupUtils.siteDataUtils.setSvgShapeStore(siteData, svgId, svgStringToStoreData(EMPTY_SHAPE));
            }
        };
    }

    /**
     * get fill colors from svg string
     * @param {string} svgString
     * @returns {{
     *  color1?: string,
     *  color2?: string,
     *  color3?: string,
     *  color4?: string,
     *  color5?: string,
     *  color6?: string,
     *  color7?: string,
     *  color8?: string,
     *  svgType: 'shape'|'color'|'tint',
     *  viewBox: string,
     *  }}
     */
    function parseSvgInfo(svgString) {
        const div = fragment.document.createElement('div');
        div.innerHTML = svgString;

        const colorNodes = div.querySelectorAll('[data-color]');
        const svgInfo = _.reduce(colorNodes, function (colors, node) {
            colors[`color${node.getAttribute('data-color')}`] = node.getAttribute('fill');
            return colors;
        }, {});
        const svgNode = div.querySelector('svg');
        svgInfo.svgType = svgNode.getAttribute('data-type') || SVG_TYPES.SHAPE;
        svgInfo.viewBox = svgNode.getAttribute('viewBox') || getUGCViewBox(svgInfo.svgType, svgNode);
        svgInfo.bbox = svgNode.getAttribute('data-bbox') || '';
        return svgInfo;
    }

    /**
     *
     * @param {string} svgType
     * @param {SVGElement} svgNode
     * @returns {string}
     */
    function getUGCViewBox(svgType, svgNode) {
        if (svgType === SVG_TYPES.UGC) {
            const width = parseInt(svgNode.getAttribute('width'), 0);
            const height = parseInt(svgNode.getAttribute('height'), 0);
            if (width && height) {
                return `0 0 ${width} ${height}`;
            }
        }
        return '';
    }

    /**
     * SvgStroe data structure
     * @param {string} svgString
     * @returns {{}}
     */
    function svgStringToStoreData(svgString) {
        return {
            [SVG_STORES.CONTENT]: svgString,
            [SVG_STORES.INFO]: parseSvgInfo(svgString),
            //todo: should we set bbox values or let layout measure
            [SVG_STORES.BOX_BOUNDARIES]: {}
        };
    }

    return {
        createSvgFetchRequest,
        svgStringToStoreData
    };
});

