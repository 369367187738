define([
    'lodash',
    'coreUtilsLib',
    'warmupUtils',
    'coreUtils/components/componentUtils',
    'coreUtils/core/countryCodes.json', // List can be found at http://en.wikipedia.org/wiki/ISO_3166-1_alpha-3
    'coreUtils/core/svg',
    'coreUtils/core/media',
    'coreUtils/core/filterHtmlString',
    'coreUtils/core/behaviorsService',
    'coreUtils/core/behaviorHandlersFactory',
    'coreUtils/core/animationsService',
    'coreUtils/core/santaTypes',
    'coreUtils/core/propsSelectorsUtils',
    'coreUtils/core/staticValue',
    'coreUtils/core/styleCollector',
    'coreUtils/core/colorParser',
    'coreUtils/core/integrations/dataCapsuleWrapper',
    'coreUtils/core/integrations/promoteAnalytics',
    'coreUtils/core/integrations/fullStory',
    'coreUtils/core/integrations/ravenUtils',
    'coreUtils/core/triggerTypesConsts',
    'coreUtils/core/linkRenderer',
    'coreUtils/core/linkTypes',
    'coreUtils/core/linkUtils',
    'coreUtils/core/menuUtils',
    'coreUtils/core/translationsLoader',
    'coreUtils/core/masterPageLayoutUtils',
    'coreUtils/core/originalValuesMapGenerator',
    'coreUtils/core/componentsAnchorsMetaData',
    'coreUtils/core/layoutAnchorsUtils',
    'coreUtils/core/wixUserApi',
    'coreUtils/core/scrollAnchorsUtils',
    'coreUtils/core/renderUtils',
    'coreUtils/core/clientSpecMapUtils',
    'coreUtils/core/scriptsOverridesService',
    'coreUtils/core/componentVisibility',
    'coreUtils/core/fileUploadManager',
    'coreUtils/core/externalScriptRegistery',
    'coreUtils/core/fullToDisplayedJson',
    'coreUtils/core/jsonUpdaterRegistrar',
    'coreUtils/core/mobileUtils',
    'coreUtils/core/modesUtils',
    'coreUtils/core/structureDimensions',
    'coreUtils/core/stubPageUtil',
    'coreUtils/core/versionUtils',
    'coreUtils/core/socialShareHandler',
    'coreUtils/core/socialCounterDatabaseAPI',
    'coreUtils/core/compsToPackages'
], function (_,
             coreUtilsLib,
             warmupUtils,
             componentUtils,
             countryCodes,
             svg,
             media,
             filterHtmlString,
             behaviorsService,
             behaviorHandlersFactory,
             animationsService,
             santaTypes,
             propsSelectorsUtils,
             staticValue,
             styleCollector,
             colorParser,
             dataCapsuleWrapper,
             promoteAnalytics,
             fullStory,
             ravenUtils,
             triggerTypesConsts,
             linkRenderer,
             linkTypes,
             linkUtils,
             menuUtils,
             translationsLoader,
             masterPageLayoutUtils,
             originalValuesMapGenerator,
             componentsAnchorsMetaData,
             layoutAnchors,
             wixUserApi,
             scrollAnchors,
             renderUtils,
             clientSpecMapUtils,
             scriptsOverridesService,
             componentVisibility,
             fileUploadManager,
             externalScriptRegistery,
             fullToDisplayedJson,
             jsonUpdaterRegistrar,
             mobileUtils,
             modesUtils,
             structureDimensions,
             stubPageUtil,
             versionUtils,
             socialShareHandler,
             socialCounterDatabaseAPI,
             compsToPackages
) {
    'use strict';

    return _.assign({},
        coreUtilsLib,
        warmupUtils,
        {
            componentUtils,
            countryCodes,
            svg,
            media,
            filterHtmlString,
            behaviorsService,
            behaviorHandlersFactory,
            animationsService,
            santaTypes,
            propsSelectorsUtils,
            staticValue,
            styleCollector,
            colorParser,
            integrations: {
                dataCapsuleWrapper,
                promoteAnalytics,
                fullStory,
                ravenUtils
            },
            triggerTypesConsts,
            linkRenderer,
            linkTypes,
            linkUtils,
            menuUtils,
            translationsLoader,
            masterPageLayoutUtils,
            originalValuesMapGenerator,
            getComponentsAnchorsMetaData: componentsAnchorsMetaData.get,
            layoutAnchors,
            wixUserApi,
            scrollAnchors,
            Store: coreUtilsLib.StoreNew,
            renderUtils,
            clientSpecMapUtils,
            scriptsOverridesService,
            componentVisibility,
            fileUploadManager,
            externalScriptRegistery,
            fullToDisplayedJson,
            jsonUpdaterRegistrar,
            mobileUtils,
            modesUtils,
            structureDimensions,
            stubPageUtil,
            versionUtils,
            socialShareHandler,
            socialCounterDatabaseAPI,
            getPackagesForComponent: compsToPackages.compToPackages,
            allPackages: compsToPackages.all,
            allButNativeComponents: compsToPackages.allButNativeComponents,
            packagesToCallbacks: compsToPackages.packagesToCallbacks,
            getPackagesToCallbacksForComponent: compsToPackages.getPackagesToCallbacksForComponent,
            getAllPackagesForAllComponents: compsToPackages.getAllPackagesForAllComponents,
            getCallbackForRequiredPackage: compsToPackages.getCallbackForRequiredPackage
        });
});
