define([
    'lodash',
    'coreUtilsLib',
    'warmupUtils',
    'coreUtils/core/linkTypes'
], function (
    _,
    coreUtilsLib,
    warmupUtils,
    linkTypes
) {
    'use strict';

    const wixUrlParser = warmupUtils.wixUrlParser;
    const sanitizeWhatsAppPhoneNumber = (str = '') => str.replace(new RegExp('[+| |-]', 'g'), '');

    const LINK_PANEL_PROPS = {
        [linkTypes.PAGE_LINK]: ['type', 'pageId'],
        [linkTypes.EXTERNAL_LINK]: ['type', 'target', 'url'],
        [linkTypes.ANCHOR_LINK]: ['type', 'anchorName', 'anchorDataId', 'pageId'],
        [linkTypes.EMAIL_LINK]: ['type', 'recipient', 'subject'],
        [linkTypes.PHONE_LINK]: ['type', 'phoneNumber'],
        [linkTypes.WHATSAPP_LINK]: ['type', 'phoneNumber'],
        [linkTypes.DOCUMENT_LINK]: ['type', 'docId', 'name'],
        [linkTypes.DYNAMIC_PAGE_LINK]: ['type', 'routerId', 'innerRoute', 'anchorDataId'],
        [linkTypes.ADDRESS_LINK]: ['type', 'address']
    };

    function getEmailLink(linkData) {
        let emailLink = `mailto:${linkData.recipient && linkData.recipient.trim() || ''}`; // eslint-disable-line no-mixed-operators
        const linkParamsArr = [];

        if (linkData.subject) {
            linkParamsArr.push(`subject=${linkData.subject}`);
        }

        if (linkData.body) {
            linkParamsArr.push(`body=${linkData.body}`);
        }

        if (linkParamsArr.length > 0) {
            emailLink += `?${linkParamsArr.join('&')}`;
        }
        return emailLink;
    }

    function getDocumentLink(linkData, siteData) {
        let docId = linkData.docId;
        const isPDF = /\.pdf$/i.test(docId);
        const staticDocsUrl = siteData.serviceTopology.staticDocsUrl;
        let href;

        if (_.includes(docId, 'ugd/') && _.includes(staticDocsUrl, '/ugd')) {
            docId = docId.substr(docId.indexOf('ugd') + 4);
        }

        href = `${staticDocsUrl}/${docId}`;

        // ?dn=document+name.ext -- will cause link to download the file with the specified name
        // for pdf, we want it to open in browser and not download. The browser attaches a download button to it anyway.
        // for non pdf, we want the link to download the file for the user
        if (isPDF) {
            if (linkData.indexable === true) {
                href += '?index=true';
            }
        } else {
            href += `?${coreUtilsLib.urlUtils.toQueryString({dn: linkData.name})}`;
        }
        return href;
    }

    function getPhoneLink(linkData) {
        return `tel:${linkData.phoneNumber}`;
    }

    function getWhatsAppLink(linkData) {
        const phoneNumber = sanitizeWhatsAppPhoneNumber(linkData.phoneNumber);
        const WHATSAPP_API_URL = 'https://api.whatsapp.com/send?phone=';
        return `${WHATSAPP_API_URL}${phoneNumber}`;
    }

    function getRelevantLinkDataFromMenuItem(menuItem) {
        let link = {};
        if (menuItem.link) {
            link = _.pick(menuItem.link, LINK_PANEL_PROPS[menuItem.link.type]);
            if (_.isObject(link.pageId)) {
                link.pageId = `#${link.pageId.id}`;
            }
            if (_.isObject(link.anchorDataId)) {
                link.anchorDataId = `#${link.anchorDataId.id}`;
            }
        }
        return link;
    }

    function navigateToLink(siteAPI, linkData, onError) { // eslint-disable-line complexity
        if (linkData) {
            switch (linkData.type) {
                case 'PageLink':
                    if (linkData.pageId) {
                        const pageId = linkData.pageId.replace('#', '');
                        const allPageIds = siteAPI.getSiteData().getAllPageIds();
                        navigateToPage(siteAPI, pageId, allPageIds, false, undefined, _.noop, onError);
                    } else if (onError) {
                        onError({
                            error: {
                                message: 'missing pageId property for PageLink'
                            }
                        });
                    }
                    break;
                case 'AnchorLink':
                    navigateToAnchor(siteAPI, linkData, onError);
                    break;
                case 'ExternalLink':
                    //handled in sdk due to popup blockers
                    break;
                case 'EmailLink':
                    window.location.href = getEmailLink(linkData);
                    break;
                case 'PhoneLink':
                    window.location.href = getPhoneLink(linkData);
                    break;
                case 'WhatsAppLink':
                    window.location.href = getWhatsAppLink(linkData);
                    break;
                case 'DocumentLink':
                    const docLink = getDocumentLink(linkData, siteAPI.getSiteData());
                    window.open(docLink, '_self');
                    break;
                case 'DynamicPageLink':
                    navigateToDynamicPage(siteAPI, linkData, linkData.anchorDataId);
                    break;
            }
        }
    }

    function navigateToAnchor(siteAPI, linkData, onError) {
        if (linkData.pageId) {
            const pageId = linkData.pageId.replace('#', '');
            if (siteAPI.getSiteData().getPrimaryPageId() === pageId || siteAPI.getSiteData().MASTER_PAGE_ID === pageId) {
                siteAPI.scrollToAnchor(linkData.anchorDataId);
            } else {
                const allPageIds = siteAPI.getSiteData().getAllPageIds();
                navigateToPage(siteAPI, pageId, allPageIds, false, linkData.anchorDataId, _.noop, onError);
            }
        } else if (onError) {
            onError({
                error: {
                    message: 'missing pageId property for AnchorLink'
                }
            });
        }
    }

    function navigateToPage(siteAPI, pageId, allPageIds, noPageTransition, anchorDataId, onNavigationComplete, onError) {
        if (_.includes(allPageIds, pageId)) {
            const actionsAspect = siteAPI.getSiteAspect('actionsAspect');
            actionsAspect.registerNavigationComplete(onNavigationComplete);
            const navInfo = {pageId};
            if (noPageTransition) {
                navInfo.transition = 'none';
            }
            if (anchorDataId) {
                navInfo.anchorData = anchorDataId;
            }
            siteAPI.navigateToPage(navInfo);
        } else if (onError) {
            onError({
                error: {
                    message: `Page id "${pageId}" was not found.`
                }
            });
        }
    }

    function navigateToDynamicPage(siteAPI, linkData, anchorDataId) {
        const url = wixUrlParser.getUrl(siteAPI.getSiteData(), linkData);
        const navigationInfo = wixUrlParser.parseUrl(siteAPI.getSiteData(), url);
        if (anchorDataId) {
            navigationInfo.anchorData = anchorDataId;
        }
        siteAPI.navigateToPage(navigationInfo, false, false);
    }

    return {
        getEmailLink,
        getDocumentLink,
        getPhoneLink,
        getWhatsAppLink,
        navigateToLink,
        navigateToPage,
        getRelevantLinkDataFromMenuItem
    };
});
