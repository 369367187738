define(['coreUtilsLib'], function (coreUtilsLib) {
    'use strict';

    function isSkinRepresentation(splitted) {
        return splitted.length > 1;
    }

    return {
        shortenStyleId(styleIdOrSkin) {
            const splitted = (styleIdOrSkin || '').split('.');
            if (isSkinRepresentation(splitted)) {
                const fullSkinNameHashValue = coreUtilsLib.hashUtils.SHA256.b64_sha256(styleIdOrSkin);
                const result = fullSkinNameHashValue.substr(fullSkinNameHashValue.length - 5) + splitted.pop();
                return `s_${result.replace(/[^a-z]/gi, '')}`;
            }
            return styleIdOrSkin;
        }
    };
});
