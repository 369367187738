define([
], function () {
    'use strict';

    const WIXAPPS_COMPS = ['wixappsCore', 'textArea', 'matrixGallery', 'slideShowGallery', 'comboBoxInput', 'dialogs'];

    const WIXAPPS_CLASSICS_COMPS = WIXAPPS_COMPS.concat(['table', 'messageView', 'wixappsClassics', 'mediaRichText']);

    const PACKAGE_MAP = {
        'wysiwyg.viewer.components.svgshape.SvgShape': 'svgShape',
        'wysiwyg.viewer.components.PopupCloseIconButton': 'svgShape',
        'wysiwyg.viewer.components.VectorImage': 'svgShape',
        'wysiwyg.viewer.components.AdminLoginButton': 'adminLoginButton',
        'wysiwyg.viewer.components.WTwitterFollow': 'wTwitterFollow',
        'wysiwyg.viewer.components.WFacebookComment': 'facebookComments',
        'wysiwyg.common.components.verticalanchorsmenu.viewer.VerticalAnchorsMenu': 'verticalAnchorsMenu',
        'wysiwyg.common.components.verticalanchorsmenu.viewer.VerticalAnchorsMenuItem': 'verticalAnchorsMenu',
        'wysiwyg.viewer.components.FacebookShare': 'facebookShare',
        'wysiwyg.viewer.components.VKShareButton': 'vKShareButton',
        'wysiwyg.common.components.youtubesubscribebutton.viewer.YouTubeSubscribeButton': 'youTubeSubscribeButton',
        'wysiwyg.viewer.components.ItunesButton': 'itunesButton',
        'wysiwyg.common.components.skypecallbutton.viewer.SkypeCallButton': 'skypeCallButton',
        'wysiwyg.viewer.components.inputs.FileUploader': 'fileUploader',
        'wysiwyg.common.components.pinitpinwidget.viewer.PinItPinWidget': 'pinItPinWidget',
        'wysiwyg.viewer.components.PopupCloseTextButton': 'popupCloseTextButton',
        'wysiwyg.viewer.components.Displayer': 'displayer',
        'wysiwyg.viewer.components.MatrixGallery': ['matrixGallery', 'imageZoom'],
        'wysiwyg.viewer.components.SiteRegionContainer': ['siteRegionContainer'],
        'wysiwyg.viewer.components.Repeater': ['repeater'],
        'wysiwyg.viewer.components.inputs.RadioButton': 'radioButton',
        'wysiwyg.viewer.components.inputs.RadioGroup': ['radioButton', 'radioGroup'],
        'wysiwyg.viewer.components.inputs.CheckboxGroup': ['checkbox', 'checkboxGroup'],
        'wysiwyg.viewer.components.documentmedia.DocumentMedia': 'documentMedia',
        'wysiwyg.viewer.components.background.bgMedia': 'backgroundCommon',
        'wysiwyg.viewer.components.background.bgImage': 'backgroundCommon',
        'wysiwyg.viewer.components.background.html5Video': 'backgroundCommon',
        'wysiwyg.viewer.components.background.iframeVideo': 'backgroundCommon',
        'wysiwyg.viewer.components.background.bgOverlay': 'backgroundCommon',
        'wysiwyg.viewer.components.inputs.DatePicker': 'datePicker',
        'wysiwyg.viewer.components.Calendar': 'datePicker',
        'wysiwyg.viewer.components.Month': 'datePicker',
        'wysiwyg.viewer.components.Day': 'datePicker',
        'wysiwyg.viewer.components.ContactForm': 'contactForm',
        'wysiwyg.viewer.components.DynamicContactForm': 'contactForm',
        'wysiwyg.common.components.subscribeform.viewer.SubscribeForm': 'subscribeForm',
        'wixapps.integration.components.inputs.TextArea': 'textArea',
        'wysiwyg.viewer.components.inputs.TextAreaInput': 'textArea',
        'wysiwyg.viewer.components.LoginSocialBar': ['loginSocialBar', 'icon', 'svgShape'],
        'wysiwyg.viewer.components.LoginSocialButton': ['loginSocialBar', 'icon', 'svgShape'],
        'wysiwyg.viewer.components.Icon': 'icon',
        'wysiwyg.viewer.components.GoogleMap': 'googleMap',
        'wysiwyg.viewer.components.SoundCloudWidget': 'soundCloudWidget',
        'wysiwyg.viewer.components.PayPalButton': 'paypalButton',
        'wysiwyg.common.components.imagebutton.viewer.ImageButton': 'imageButton',
        'wysiwyg.viewer.components.LinkBar': 'linkBar',
        'wysiwyg.viewer.components.LinkBarItem': 'linkBar',
        'wysiwyg.viewer.components.inputs.ComboBoxInput': 'comboBoxInput', //move out and purge usages
        'wysiwyg.common.components.spotifyplayer.viewer.SpotifyPlayer': 'spotifyPlayer',
        'wysiwyg.common.components.spotifyfollow.viewer.SpotifyFollow': 'spotifyFollow',
        'wysiwyg.viewer.components.TwitterFeed': 'twitterFeed',
        'wysiwyg.common.components.backtotopbutton.viewer.BackToTopButton': 'backToTopButton',
        'wysiwyg.viewer.components.svgPrimitive': 'svgCommon',
        'wysiwyg.viewer.components.WFacebookLike': 'facebookLike',
        'wysiwyg.common.components.facebooklikebox.viewer.FacebookLikeBox': 'facebookLikeBox',
        'wysiwyg.viewer.components.FlickrBadgeWidget': 'flickrBadgeWidget',
        'wysiwyg.common.components.rssbutton.viewer.RSSButton': 'rssButton',
        'wysiwyg.viewer.components.mobile.TinyMenu': ['tinyMenu', 'loginSocialBar', 'icon', 'svgShape'],
        'wysiwyg.viewer.components.ExpandableMenu': 'expandableMenu',
        'wysiwyg.viewer.components.Popover': 'santa-components/popover', //
        'wysiwyg.viewer.components.WGooglePlusOne': 'wGooglePlusOne',
        'wysiwyg.common.components.pinterestpinit.viewer.PinterestPinIt': 'pinterestPinIt',
        'wysiwyg.viewer.components.PinterestFollow': 'pinterestFollow',
        'wysiwyg.viewer.components.WTwitterTweet': 'wTwitterTweet',
        'wysiwyg.viewer.components.AudioPlayer': ['audioPlayer', 'audioCommon'],
        'wysiwyg.viewer.components.LoginButton': ['loginButton', 'dialogs'],
        'wysiwyg.viewer.components.HtmlComponent': 'htmlComponent',
        'wysiwyg.viewer.components.MediaPlayer': 'mediaPlayer',
        'wysiwyg.viewer.components.MediaOverlayControls': ['mediaControls', 'svgShape'],
        'wysiwyg.viewer.components.MediaControls': 'mediaControls',
        'wysiwyg.viewer.components.MediaControlPlay': 'mediaControls',
        'wysiwyg.viewer.components.MediaControlFullscreen': 'mediaControls',
        'wysiwyg.viewer.components.MediaControlVolume': 'mediaControls',
        'wysiwyg.viewer.components.MediaControlProgress': 'mediaControls',
        'wysiwyg.viewer.components.MediaControlTime': 'mediaControls',
        'wysiwyg.viewer.components.MediaControlStoryboard': 'mediaControls',
        'wysiwyg.viewer.components.SlideShowGallery': ['slideShowGallery', 'imageZoom'],
        'wysiwyg.common.components.singleaudioplayer.viewer.SingleAudioPlayer': ['singleAudioPlayer', 'audioCommon'],
        'wysiwyg.viewer.components.QuickActionBar': 'quickActionBar',
        'wysiwyg.viewer.components.QuickActionBarItem': 'quickActionBar',
        'wysiwyg.viewer.components.BoxSlideShowSlide': 'boxSlideShowSlide',
        'wysiwyg.viewer.components.StripContainerSlideShowSlide': ['stripSlideShowSlide', 'boxSlideShowSlide'],
        'wysiwyg.viewer.components.PopupContainer': 'popupContainer',
        'wysiwyg.viewer.components.StripContainer': 'stripContainer',
        'wysiwyg.viewer.components.StripColumnsContainer': 'stripColumnsContainer',
        'wysiwyg.common.components.exitmobilemode.viewer.ExitMobileMode': 'exitMobileModeButton',
        'tpa.viewer.components.Masonry': 'tpaGalleries',
        'tpa.viewer.components.Accordion': 'tpaGalleries',
        'tpa.viewer.components.Impress': 'tpaGalleries',
        'tpa.viewer.components.Freestyle': 'tpaGalleries',
        'tpa.viewer.components.Collage': 'tpaGalleries',
        'tpa.viewer.components.Honeycomb': 'tpaGalleries',
        'tpa.viewer.components.StripShowcase': 'tpaGalleries',
        'tpa.viewer.components.StripSlideshow': 'tpaGalleries',
        'tpa.viewer.components.Thumbnails': 'tpaGalleries',
        'wysiwyg.viewer.components.tpapps.TPA3DGallery': 'tpaGalleries',
        'wysiwyg.viewer.components.tpapps.TPA3DCarousel': 'tpaGalleries',
        'wysiwyg.viewer.components.MessageView': 'messageView',
        'wysiwyg.viewer.components.FlashComponent': ['flashComponent', 'swfobject'],
        'wysiwyg.viewer.components.BoxSlideShow': 'stripSlideShow',
        'wysiwyg.viewer.components.StripContainerSlideShow': 'stripSlideShow',
        'wysiwyg.viewer.components.MobileActionsMenu': 'mobileActionsMenu',
        'wysiwyg.components.imageZoom': 'imageZoom',
        'wysiwyg.viewer.components.MediaZoom': 'imageZoom',
        'wysiwyg.components.ImageZoomDisplayer': 'imageZoom',
        'wysiwyg.viewer.components.MobileMediaZoom': 'imageZoom',
        'wysiwyg.viewer.components.TouchMediaZoom': 'imageZoom',
        'wysiwyg.viewer.components.TouchMediaZoomItem': 'imageZoom',
        'wysiwyg.common.components.verticalmenu.viewer.VerticalMenu': 'verticalMenu',
        'wysiwyg.common.components.disquscomments.viewer.DisqusComments': 'disqusComments',
        'wysiwyg.viewer.components.inputs.Checkbox': 'checkbox',
        'wixapps.integration.components.Icon': 'wixappsCore',
        'wixapps.integration.components.ImageButton': 'wixappsCore',
        'wixapps.integration.components.Toggle': 'wixappsCore',
        'wysiwyg.viewer.components.Grid': ['gridComponent', 'ag-grid'],
        'wysiwyg.viewer.components.Table': 'table',
        'wysiwyg.viewer.components.dialogs.NotificationDialog': 'dialogs',
        'wysiwyg.viewer.components.dialogs.EnterPasswordDialog': 'dialogs',
        'wysiwyg.viewer.components.dialogs.siteMemberDialogs.SignUpDialog': 'dialogs',
        'wysiwyg.viewer.components.dialogs.siteMemberDialogs.MemberLoginDialog': 'dialogs',
        'wysiwyg.viewer.components.dialogs.siteMemberDialogs.RequestPasswordResetDialog': 'dialogs',
        'wysiwyg.viewer.components.dialogs.siteMemberDialogs.ResetPasswordDialog': 'dialogs',
        'wysiwyg.viewer.components.inputs.ErasableTextInput': 'dialogs',
        'wysiwyg.components.viewer.inputs.InputWithValidation': 'dialogs',
        'wysiwyg.viewer.components.SliderGallery': 'imageZoom',
        'wysiwyg.viewer.components.LanguageSelector': 'languageSelector',
        'wysiwyg.viewer.components.MediaContainer': 'mediaContainer',
        'wysiwyg.viewer.components.HoverBox': 'mediaContainer',
        'wysiwyg.viewer.components.MediaBox': 'mediaContainer',
        'wysiwyg.viewer.components.Column': 'mediaContainer',
        'wysiwyg.viewer.components.EbayItemsBySeller': 'ebayItemsBySeller',
        'platform.components.AppController': 'controller',
        'wysiwyg.viewer.components.MediaRichText': 'mediaRichText',
        'wysiwyg.viewer.components.ImageButtonWithText': 'wixappsCore',
        'wysiwyg.viewer.components.inputs.ColorOption': 'wixappsClassics',
        'ecommerce.integration.components.MobileColorOption': 'wixappsClassics',
        'wysiwyg.common.components.NumericStepper': 'wixappsClassics',
        'wysiwyg.common.components.inputs.OptionsListInput': 'wixappsClassics',
        'wysiwyg.common.components.inputs.SelectOptionsList': 'wixappsClassics',
        'wysiwyg.viewer.components.inputs.TextOption': 'wixappsClassics',
        'ecommerce.integration.components.MobileTextOption': 'wixappsClassics',
        'wysiwyg.viewer.components.WixFreemiumBanner': 'wixFreemiumBanner',
        'wysiwyg.viewer.components.MenuContainer': 'inlinePopup',
        'wysiwyg.viewer.components.MenuToggle': 'inlinePopup',
        'wysiwyg.viewer.components.InlinePopup': 'inlinePopup',
        'wysiwyg.viewer.components.InlinePopupToggle': 'inlinePopup',

        'wixapps.integration.components.AppPartZoom': WIXAPPS_CLASSICS_COMPS,
        'wixapps.integration.components.AppPart': WIXAPPS_CLASSICS_COMPS,
        'wixapps.integration.components.AppPart2': WIXAPPS_COMPS.concat('wixappsBuilder')
    };

    return PACKAGE_MAP;
});
